import { useEffect } from "react";
import { inject } from "mobx-react";

import { useTranslation } from "react-i18next";
import { socialConfigAbstract, SocialNetworkData } from "@inferno/renderer-shared-core";
import { SocialIcons } from "@inferno/renderer-shared-ui";
import { Container } from "../../ui";
import type { PageBlockInterface } from "../../page-blocks/Block.component";
import "./MicrositeInfo.style.scss";
import type { SitesTemplateInfernoMicrositeContact } from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";

interface MicrositeInfoProps extends PageBlockInterface {
  store?: Store;
}

interface ContactProps {
  title: string;
  item: string | null;
  type: string;
  label?: string;
}

const Social = ({ network }: { network: SocialNetworkData[] }) => {
  const { t } = useTranslation();
  return network.length ? (
    <section className="social">
      <h2>{t("follow")}</h2>
      <SocialIcons networks={network} />
    </section>
  ) : null;
};

const Contact = (props: ContactProps) => {
  const { t } = useTranslation();
  let href = "";
  let label = props.label;
  switch (props.type) {
    case "email":
      href = `mailto:${props.item}`;
      break;
    case "phone":
    case "text":
      href = `tel:${props.item}`;
      label = label ? label + ": " : label;
      break;
  }
  if (props.item) {
    return (
      <section className={`contact type-${props.type}`}>
        <h2>{t(props.title)}</h2>
        {label ? <label>{label}</label> : null}
        <a href={href}>{props.item}</a>
        {props.type === "text" ? <p className="text-disclaimer">{t("disclaimer")}</p> : null}
      </section>
    );
  }
  return null;
};

const hasSomeContactMethod = (network: SocialNetworkData[], contact?: SitesTemplateInfernoMicrositeContact | null) =>
  Boolean(
    network.length ||
      contact?.phone_number ||
      contact?.txt_number ||
      contact?.email_address ||
      contact?.full_bio?.length,
  );

export const MicrositeInfo = inject("store")((props: MicrositeInfoProps) => {
  const { store, block } = props;
  if (!store) {
    return null;
  }
  const { microsite } = store;
  if (!microsite || !microsite.config) {
    return null;
  }
  const { t } = useTranslation();
  const { social, contact } = microsite.sections;
  useEffect(() => store.storeBlock({ ...block }));

  const network = social ? socialConfigAbstract(social) : [];
  return hasSomeContactMethod(network, contact) ? (
    <Container className="component-microsite-info" block={true}>
      {contact?.full_bio ? (
        <section className="bio-text" dangerouslySetInnerHTML={{ __html: contact.full_bio }} />
      ) : null}
      <Social network={network} />
      <Contact type="email" title={t("email")} item={contact?.email_address || null} />
      <Contact type="phone" label={t("studio_line")} title={t("call")} item={contact?.phone_number || null} />
      <Contact type="text" title={t("text")} label={t("studio_line")} item={contact?.txt_number || null} />
    </Container>
  ) : null;
});

export default MicrositeInfo;
